import DynamicAutocomplete from "./DynamicAutocomplete";
import {VendorsCategoriesListAllURL} from "../../urls";

export const VendorCategoryAutoComplete = (props)=>{
    const params = {vendor: props.vendor, brand: props.brand};


    return(
        <DynamicAutocomplete
            {...props}
            exclude={props.exclude}
            fetch_url={VendorsCategoriesListAllURL}
            title_key="name_en"
            mode={props.multiple ? "multiple":""}
            params_array={[props.vendor, props.brand]}
            params={params}
        />
    )
}

export default VendorCategoryAutoComplete;
