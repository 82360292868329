import CustomModal from "../../../global/components/CustomModal";
import {Col, Collapse, Row} from "antd";
import {useState} from "react";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {RulesURL} from "../../../global/urls";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";

const {Panel} = Collapse;


const rule_for_options = [{title: "Admin", value: "Admin"}, {title: "Vendor", value: "Vendor"},]


const rule_depend_on = {

    'can_view_rule': [],
    'can_add_rule': ['can_view_rule'],
    'can_edit_rule': ['can_view_rule'],
    'can_delete_rule': ['can_view_rule'],
    'can_change_user_status': ['can_view_rule'],

    'can_view_user': [],
    'can_add_user': ['can_view_user'],
    'can_edit_user': ['can_view_user'],
    'can_delete_user': ['can_view_user'],
    'can_export_user': ['can_view_user'],

    "can_view_vendor": [],
    "can_add_vendor": ['can_view_vendor'],
    "can_edit_vendor": ['can_view_vendor'],
    "can_delete_vendor": ['can_view_vendor'],

    "can_view_vendor_document": [],
    "can_add_vendor_document": ['can_view_vendor_document'],
    "can_edit_vendor_document": ['can_view_vendor_document'],
    "can_delete_vendor_document": ['can_view_vendor_document'],

    "can_view_vendor_category": [],
    "can_add_vendor_category": ['can_view_vendor_category'],
    "can_edit_vendor_category": ['can_view_vendor_category'],
    "can_delete_vendor_category": ['can_view_vendor_category'],

    "can_view_advertisement": [],
    "can_add_advertisement": ['can_view_advertisement'],
    "can_edit_advertisement": ['can_view_advertisement'],
    "can_delete_advertisement": ['can_view_advertisement'],

    "can_view_blog": [],
    "can_add_blog": ['can_view_blog'],
    "can_edit_blog": ['can_view_blog'],
    "can_delete_blog": ['can_view_blog'],

    "can_view_product": [],
    "can_add_product": ['can_view_product'],
    "can_edit_product": ['can_view_product'],
    "can_delete_product": ['can_view_product'],

    "can_view_product_size": [],
    "can_add_product_size": ['can_view_product_size'],
    "can_edit_product_size": ['can_view_product_size'],
    "can_delete_product_size": ['can_view_product_size'],

    "can_view_product_category": [],
    "can_add_product_category": ['can_view_product_category'],
    "can_edit_product_category": ['can_view_product_category'],
    "can_delete_product_category": ['can_view_product_category'],


    "can_view_order": [],
    "can_add_order": ['can_view_order'],
    "can_edit_order": ['can_view_order'],
    "can_delete_order": ['can_view_order'],
    "can_update_order_status": ['can_view_order'],
    "can_update_delivery_by": ['can_update_delivery_by'],
    "can_receive_new_order_email": [''],
    "can_print_order_recipient": ['can_view_order'],
}


export const RuleFormModal = props => {
    const [data, setData] = useState({rule_for: "Admin", ...props.data});
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {
        if (loading) return;

        let temp_data = {...data};
        temp_data[name] = value;

        if (!['name', 'rule_for'].includes(name) && value) {
            rule_depend_on[name].map(depend_on => {
                temp_data[depend_on] = true;
            })
        }

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);

        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(RulesURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${RulesURL}${data.id}`, "PUT", data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Rule", `${data.name} rule has been added`)
            } else {
                openNotification("success", "Update Rule", `${data.name} rule has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (<CustomModal title="Rule Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Name"
                        name="name"
                        value={data.name}
                        onChange={handleFieldChange}
                        error={helper_text.name !== undefined}
                        helper_text={helper_text.name}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomSelectField
                        label="Rule For"
                        name="rule_for"
                        value={data.rule_for}
                        onChange={handleFieldChange}
                        error={helper_text.rule_for !== undefined}
                        helper_text={helper_text.rule_for}
                        options={rule_for_options}
                        disabled={isValidValue(data.id)}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <Collapse
                        expandIconPosition="right"
                        className="bg-none border-0"
                    >

                        <Panel header="Users" key="1">
                            <CustomCheckBox name="can_view_user" label="View User"
                                            value={data.can_view_user} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_user" label="Add User" value={data.can_add_user}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_user" label="Update User"
                                            value={data.can_edit_user} onChange={handleFieldChange}/>
                            {data.rule_for === "Admin" && (
                                    <CustomCheckBox name="can_export_user" label="Export Users Data"
                                                    value={data.can_export_user} onChange={handleFieldChange}/>
                                )}

                            {/*<CustomCheckBox name="can_delete_user" label="Delete User"*/}
                            {/*                value={data.can_delete_user} onChange={handleFieldChange}/>*/}
                        </Panel>


                        {data.rule_for === "Admin" && (<Panel header="Rules" key="2">
                                <CustomCheckBox name="can_view_rule" label="View Rule"
                                                value={data.can_view_rule} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_add_rule" label="Add Rule" value={data.can_add_rule}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_rule" label="Update Rule"
                                                value={data.can_edit_rule} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_rule" label="Delete Rule"
                                                value={data.can_delete_rule} onChange={handleFieldChange}/>
                            </Panel>)}


                        <Panel header="Products" key="3">
                            <CustomCheckBox name="can_view_product" label="View Product"
                                            value={data.can_view_product} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_product" label="Add Product" value={data.can_add_product}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_product" label="Update Product"
                                            value={data.can_edit_product} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_product" label="Delete Product"
                                            value={data.can_delete_product} onChange={handleFieldChange}/>

                            <div className="mt-3"/>

                            <CustomCheckBox name="can_view_product_size" label="View Product Size"
                                            value={data.can_view_product_size} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_product_size" label="Add Product Size" value={data.can_add_product_size}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_product_size" label="Update Product Size"
                                            value={data.can_edit_product_size} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_product_size" label="Delete Product Size"
                                            value={data.can_delete_product_size} onChange={handleFieldChange}/>

                            {data.rule_for === "Admin" && (<>
                                    <div className="mt-3"/>

                                    <CustomCheckBox name="can_view_product_category" label="View Product Category"
                                                    value={data.can_view_product_category} onChange={handleFieldChange}/>
                                    <CustomCheckBox name="can_add_product_category" label="Add Product Category"
                                                    value={data.can_add_product_category}
                                                    onChange={handleFieldChange}/>
                                    <CustomCheckBox name="can_edit_product_category" label="Update Product Category"
                                                    value={data.can_edit_product_category} onChange={handleFieldChange}/>
                                    <CustomCheckBox name="can_delete_product_category" label="Delete Product Category"
                                                    value={data.can_delete_product_category} onChange={handleFieldChange}/>
                                </>)}

                        </Panel>


                        {data.rule_for === "Admin" && (<Panel header="Advertisements" key="8">
                            <CustomCheckBox name="can_view_advertisement" label="View Advertisement"
                                            value={data.can_view_advertisement} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_advertisement" label="Add Advertisement"
                                            value={data.can_add_advertisement}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_advertisement" label="Update Advertisement"
                                            value={data.can_edit_advertisement} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_advertisement" label="Delete Advertisement"
                                            value={data.can_delete_advertisement} onChange={handleFieldChange}/>
                        </Panel>)}


                        {data.rule_for === "Admin" && (<Panel header="Blogs" key="9">
                            <CustomCheckBox name="can_view_blog" label="View Blog"
                                            value={data.can_view_blog} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_add_blog" label="Add Blog"
                                            value={data.can_add_blog}
                                            onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_edit_blog" label="Update Blog"
                                            value={data.can_edit_blog} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_blog" label="Delete Blog"
                                            value={data.can_delete_blog} onChange={handleFieldChange}/>
                        </Panel>)}

                    </Collapse>
                </Col>

                {/*right*/}
                {/*================================================================================================*/}
                {/*================================================================================================*/}

                <Col xs={24} md={12}>
                    <Collapse
                        expandIconPosition="right"
                        className="bg-none border-0"

                    >


                        <Panel header="Vendors" key="5">
                            <CustomCheckBox name="can_view_vendor" label="View Vendor"
                                            value={data.can_view_vendor} onChange={handleFieldChange}/>

                            {data.rule_for === "Admin" && (
                                <CustomCheckBox name="can_add_vendor" label="Add Vendor" value={data.can_add_vendor}
                                                onChange={handleFieldChange}/>)}
                            <CustomCheckBox name="can_edit_vendor" label="Update Vendor"
                                            value={data.can_edit_vendor} onChange={handleFieldChange}/>
                            {data.rule_for === "Admin" && (
                                <CustomCheckBox name="can_delete_vendor" label="Delete Vendor"
                                                value={data.can_delete_vendor} onChange={handleFieldChange}/>)}
                        </Panel>

                        {data.rule_for === "Admin" && (<Panel header="Vendors Category" key="6">
                        <CustomCheckBox name="can_view_vendor_category" label="View Vendor Category"
                                        value={data.can_view_vendor_category} onChange={handleFieldChange}/>

                        <CustomCheckBox name="can_add_vendor_category" label="Add Vendor Category"
                                        value={data.can_add_vendor_category}
                                        onChange={handleFieldChange}/>
                        <CustomCheckBox name="can_edit_vendor_category" label="Update Vendor Category"
                                        value={data.can_edit_vendor_category} onChange={handleFieldChange}/>
                        <CustomCheckBox name="can_delete_vendor_category" label="Delete Vendor Category"
                                        value={data.can_delete_vendor_category} onChange={handleFieldChange}/>
                        </Panel>)}

                        {data.rule_for === "Admin" && (<Panel header="Vendors Document" key="7">
                                <CustomCheckBox name="can_view_vendor_document" label="View Vendor Document"
                                                value={data.can_view_vendor_document} onChange={handleFieldChange}/>

                                <CustomCheckBox name="can_add_vendor_document" label="Add Vendor Document"
                                                value={data.can_add_vendor_document}
                                                onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_edit_vendor_document" label="Update Vendor Document"
                                                value={data.can_edit_vendor_document} onChange={handleFieldChange}/>
                                <CustomCheckBox name="can_delete_vendor_document" label="Delete Vendor Document"
                                                value={data.can_delete_vendor_document} onChange={handleFieldChange}/>
                            </Panel>)}



                        <Panel header="Orders" key="4">
                            <CustomCheckBox name="can_view_order" label="View Orders"
                                            value={data.can_view_order} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_delete_order" label="Delete Orders"
                                            value={data.can_delete_order} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_update_order_status" label="Update Order Status"
                                            value={data.can_update_order_status} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_update_delivery_by" label="Update Order Delivery By"
                                            value={data.can_update_delivery_by} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_receive_new_order_email" label="Receive Email For New Order"
                                            value={data.can_receive_new_order_email} onChange={handleFieldChange}/>
                            <CustomCheckBox name="can_print_order_recipient" label="Print Order Recipient"
                                            value={data.can_print_order_recipient} onChange={handleFieldChange}/>
                            {data.rule_for === "Admin" && (
                                <CustomCheckBox name="can_export_order" label="Export Orders Data"
                                                value={data.can_export_order} onChange={handleFieldChange}/>
                            )}
                        </Panel>

                    </Collapse>
                </Col>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>)
}
